import * as React from 'react'
import { graphql } from 'gatsby' // to query for image data
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import Seo from '../components/seo'
import SiteWidthWrapper from '../components/wrappers/siteWidthWrapper'
// import HomeBG from "../images/bg/home.jpg"

const Gallery = ({ data }) => {
  // const [showModal, setShowModal] = useState({
  //   show: false,
  // })

  // const handleClick = (image) => {
  //   setShowModal({ show: !showModal.show, image })
  // }
  // useEffect is included so that the console.log is called after showModal is
  // change and accurately reflects its value
  // useEffect(() => {
  //   console.log('useEffect log ---> ', showModal)
  // }, [showModal])

  return (
    <Layout>
      <Seo
        title="FenceLink Gallery and Portfolio"
        description="Residential, Commercial, Repairs &amp; Auger Services"
      />

      <SiteWidthWrapper>
        <Heading>Our Gallery</Heading>
        <Container>
          {data.allFile.edges.map((image) => {
            return (
              <div key={image.node.id}>
                {/* href={image.node.childImageSharp.original.src} */}
                  <GatsbyImage
                    image={getImage(image.node.childImageSharp.gatsbyImageData)}
                    alt={image.node.name}
                  />
              </div>
            )
          })}
        </Container>
      </SiteWidthWrapper>

      {/*{showModal.show && (*/}
      {/*  <Lightbox*/}
      {/*    onClick={() => {*/}
      {/*      handleClick()*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <GatsbyImage image={getImage(showModal.image)} alt="temp" />*/}
      {/*  </Lightbox>*/}
      {/*)}*/}
    </Layout>
  )
}

export default Gallery

// start query
export const query = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "gallery" } }) {
      edges {
        node {
          id
          name
          childImageSharp {
            id
            gatsbyImageData(aspectRatio: 1.5, placeholder: TRACED_SVG, formats: AUTO, webpOptions: {quality: 10})
#            original {
#              src
#            }
          }
        }
      }
    }
  }
`
// end query

// start styling

const Heading = styled.h1`
  color: var(--blue);
  text-align: center;
  font-size: 5.3rem;
`

const Container = styled.div`
  margin-top: 64px;
  max-width: 1136px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2em;

  @media (min-width: 1025px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }
`

// const Lightbox = styled.div`
//   background-color: rgba(0, 0, 0, 0.8);
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//
//   transform: translate(-50%, -50%);
//   width: 80vw;
//   height: auto;
//   z-index: 999;
// `
